export default `
  body {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }
`;
