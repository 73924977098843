import React from 'react';
import { Link } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';
import { ConfigProvider, Layout as AntdLayout, Divider } from 'antd';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import globalStyles from '../styles/globalStyles.js';

const StyledLink = styled(Link)`
  margin-right: 15px;
  margin-left: 15px;
  color: rgba(255, 255, 255, 0.65);
  white-space: nowrap;

  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
`;
const StyledLinkHideSm = styled(StyledLink)`
  @media(max-width: 576px) {
    display: none !important;
  }
`;

const Header = styled(AntdLayout.Header)`
  display: flex;
  justify-content: space-between;
  z-index: 1;

  @media(max-width: 576px) {
    padding: 0 24px;
  }
`;

const H1 = styled.h1`
  color: white;
  margin: -2px 35px 0 0;
  font-weight: 900;

  @media(max-width: 576px) {
    margin-right: 15px;
  }
`;

const GlobalStyle = createGlobalStyle`${globalStyles}`;
const antdTheme = {
  token: {
    borderRadius: 4,
    fontSize: 18,
  },
};

export default function Layout({ children, location }) {
  const { menuLinks } = useSiteMetadata();
  const onLandingPage = location === '/';

  return (
    <ConfigProvider theme={antdTheme}>
      <AntdLayout className="layout" style={{ minHeight: '100vh', overflow: 'hidden' }}>
        <Header style={{ ...(onLandingPage ? { background: 'transparent' } : {}) }}>
          <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <Link to="/">
              <H1>WZRD</H1>
            </Link>
          </div>

          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            {menuLinks.map((link) => (
              <StyledLink key={link.name} to={link.slug}>
                {link.name}
              </StyledLink>
            ))}
            
            <StyledLinkHideSm href="https://app.wzrd.ai/login">
              Login
            </StyledLinkHideSm>
          </div>
        </Header>

        <AntdLayout.Content style={{ background: '#fff', padding: 24, paddingBottom: 48, minHeight: 'calc(100vh - 200px)' }}>
          {children}
        </AntdLayout.Content>

        <AntdLayout.Footer
          style={{
            position: 'relative',
            paddingLeft: 24,
            paddingRight: 24,
            ...(onLandingPage ? {
              background: 'transparent',
              color: 'white',
            } : {}),
          }}
        >
          <div style={{ fontWeight: 'lighter', textAlign: 'center', fontSize: '95%', marginBottom: 5 }}><i>a.k.a. "wizard.ai video editor"</i></div>
          <div
            style={{
              margin: '0 auto',
              // width: 500,
              maxWidth: '100%',
              display: 'flex',
              // justifyContent: 'space-between',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span><span style={{ fontWeight: 900 }}>WZRD</span> © {(new Date()).getFullYear()}</span>

            {/* <Link to="/privacy-policy" style={{ filter: 'saturate(0)' }}>
              Privacy Policy
            </Link> */}

            <Divider type="vertical" />

            <Link to="/terms-of-use">
              Terms of Use
            </Link>

            <Divider type="vertical" />

            <Link to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
        </AntdLayout.Footer>

        <GlobalStyle />
      </AntdLayout>
    </ConfigProvider>
  );
}
